import restaurantData from './restaurantData';
import supermarketData from './supermarketData';
import cafeData from './cafeData';
import hotelData from './hotelData';
import libraryData from './librariesdata';
import movietheaterData from './movietheaterData';
import pharmacyData from './pharmacyData';
import clothingStoreData from './clothingstoreData';
import pastryStoreData from './bakeryData';
import { IData } from '../interfaces/StoreInterface';

const setOfData: IData = {
    Restaurant: restaurantData,
    Supermarket: supermarketData,
    Cafe: cafeData,
    Hotel: hotelData,
    Library: libraryData,
    Theater: movietheaterData,
    Pharmacy: pharmacyData,
    ClothingStore: clothingStoreData,
    PastryStore: pastryStoreData
}


export default setOfData;