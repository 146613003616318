import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import ErrorBoundary from '../util/ErrorBoundary';

const HomePage = lazy(() => import('./HomePage'));
const DetailsPage = lazy(() => import('./DetailsPage'));
const OrderListPage = lazy(() => import('./OrderListPage'));
const OrderConfirmPage = lazy(() => import('./OrderConfirmPage'));
const PaymentPage = lazy(() => import('./PaymentPage'));
const PaymentConfirmPage = lazy(() => import('./PaymentConfirmPage'));
const WalletPage = lazy(() => import('./WalletPage'));
const ChatPage = lazy(() => import('./ChatPage'));
const SelectionPage = lazy(() => import('./SelectionPage'));
const StorePage = lazy(() => import('./StorePage'));

const Pages = () => {
    return(
        <ErrorBoundary>
             <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route path={routes.HOME} exact component={HomePage} />
                    <Route path={routes.SELECT_STORE} component={SelectionPage} />
                    <Route path={routes.STORE_DETAIL} component={DetailsPage} />
                    <Route path={routes.ORDER_LIST} component={OrderListPage} />
                    <Route path={routes.ORDER_CONFIRM} component={OrderConfirmPage} />
                    <Route path={routes.PAYMENT} component={PaymentPage} />
                    <Route path={routes.PAYMENT_CONFIRM} component={PaymentConfirmPage} />
                    <Route path={routes.WALLET} component={WalletPage} />
                    <Route path={routes.CHAT} component={ChatPage} />
                    <Route path={routes.STORE} component={StorePage} />
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default Pages;