import { v4 as uuidv4 } from 'uuid';
import foodimage from '../assets/foodimage.png';
import salad from '../assets/salad.png';
import dessert from '../assets/dessert.png';
import cola from '../assets/cola.png';
import chickenwings from '../assets/chickenwings.png';
import chickennuggets from '../assets/chickennuggets.png';
import garlicBread from '../assets/garlicBread.png';
import fanta from '../assets/fanta.png';
import nachos from '../assets/nachos.png';
import potatoes from '../assets/potatoes.png';
import burger from '../assets/burger.png';
import cookie from '../assets/cookie.png';
import macroon from '../assets/macroon.png';
import restaurantImage from '../assets/restaurantImage.png';

const data = [
    {
        id: uuidv4(),
        name: 'Kanoun',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: restaurantImage,
        tags: [
            "Italian",
            "2020 Hot",
            "Best Pizza"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Appetizers',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Salad',
                        ingredients: ['Tomato', 'Lettuce', 'Olive Oil'],
                        stockImageUrl: salad,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic Bread',
                        ingredients: ['Garlic', 'Bread', 'Olive Oil'],
                        stockImageUrl: garlicBread,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Wings',
                        ingredients: ['Chicken', 'Soy sauce', 'Chill Sauce'],
                        stockImageUrl: chickenwings,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Nuggets',
                        ingredients: ['Chicken', 'breadcrumbs'],
                        stockImageUrl: chickennuggets,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Plate',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mashed Potatoes',
                        ingredients: ['Potatoes', 'Sausage', 'Cheese'],
                        stockImageUrl: potatoes,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Nachos',
                        ingredients: ['Tortilla', 'Tomato', 'Onion'],
                        stockImageUrl: nachos,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        ingredients: ['Pepproni', 'Cheese', 'Onion'],
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        ingredients: ['Beef Patty', 'Cheese', 'Onion', 'Tomato', 'Buns'],
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dessert',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Macroon',
                        stockImageUrl: macroon,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tiramisu',
                        stockImageUrl: dessert,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Drinks',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 1
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fanta',
                        stockImageUrl: fanta,
                        price: 1
                    }
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Snack Valentino',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: restaurantImage,
        tags: [
            "Italian",
            "2020 Hot",
            "Best Pizza"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Appetizers',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Salad',
                        ingredients: ['Tomato', 'Lettuce', 'Olive Oil'],
                        stockImageUrl: salad,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic Bread',
                        ingredients: ['Garlic', 'Bread', 'Olive Oil'],
                        stockImageUrl: garlicBread,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Wings',
                        ingredients: ['Chicken', 'Soy sauce', 'Chill Sauce'],
                        stockImageUrl: chickenwings,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Nuggets',
                        ingredients: ['Chicken', 'breadcrumbs'],
                        stockImageUrl: chickennuggets,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Plate',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mashed Potatoes',
                        ingredients: ['Potatoes', 'Sausage', 'Cheese'],
                        stockImageUrl: potatoes,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Nachos',
                        ingredients: ['Tortilla', 'Tomato', 'Onion'],
                        stockImageUrl: nachos,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        ingredients: ['Pepproni', 'Cheese', 'Onion'],
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        ingredients: ['Beef Patty', 'Cheese', 'Onion', 'Tomato', 'Buns'],
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dessert',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Macroon',
                        stockImageUrl: macroon,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tiramisu',
                        stockImageUrl: dessert,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Drinks',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 1
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fanta',
                        stockImageUrl: fanta,
                        price: 1
                    }
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: `Birdy's`,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: restaurantImage,
        tags: [
            "Italian",
            "2020 Hot",
            "Best Pizza"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Appetizers',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Salad',
                        ingredients: ['Tomato', 'Lettuce', 'Olive Oil'],
                        stockImageUrl: salad,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic Bread',
                        ingredients: ['Garlic', 'Bread', 'Olive Oil'],
                        stockImageUrl: garlicBread,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Wings',
                        ingredients: ['Chicken', 'Soy sauce', 'Chill Sauce'],
                        stockImageUrl: chickenwings,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Nuggets',
                        ingredients: ['Chicken', 'breadcrumbs'],
                        stockImageUrl: chickennuggets,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Plate',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mashed Potatoes',
                        ingredients: ['Potatoes', 'Sausage', 'Cheese'],
                        stockImageUrl: potatoes,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Nachos',
                        ingredients: ['Tortilla', 'Tomato', 'Onion'],
                        stockImageUrl: nachos,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        ingredients: ['Pepproni', 'Cheese', 'Onion'],
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        ingredients: ['Beef Patty', 'Cheese', 'Onion', 'Tomato', 'Buns'],
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dessert',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Macroon',
                        stockImageUrl: macroon,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tiramisu',
                        stockImageUrl: dessert,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Drinks',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 1
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fanta',
                        stockImageUrl: fanta,
                        price: 1
                    }
                ]
            },
        ]
    },
    {
        id:uuidv4(),
        name: 'Mast',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: restaurantImage,
        tags: [
            "Italian",
            "2020 Hot",
            "Best Pizza"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Appetizers',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Salad',
                        ingredients: ['Tomato', 'Lettuce', 'Olive Oil'],
                        stockImageUrl: salad,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic Bread',
                        ingredients: ['Garlic', 'Bread', 'Olive Oil'],
                        stockImageUrl: garlicBread,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Wings',
                        ingredients: ['Chicken', 'Soy sauce', 'Chill Sauce'],
                        stockImageUrl: chickenwings,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Nuggets',
                        ingredients: ['Chicken', 'breadcrumbs'],
                        stockImageUrl: chickennuggets,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Plate',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mashed Potatoes',
                        ingredients: ['Potatoes', 'Sausage', 'Cheese'],
                        stockImageUrl: potatoes,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Nachos',
                        ingredients: ['Tortilla', 'Tomato', 'Onion'],
                        stockImageUrl: nachos,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        ingredients: ['Pepproni', 'Cheese', 'Onion'],
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        ingredients: ['Beef Patty', 'Cheese', 'Onion', 'Tomato', 'Buns'],
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dessert',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Macroon',
                        stockImageUrl: macroon,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tiramisu',
                        stockImageUrl: dessert,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Drinks',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 1
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fanta',
                        stockImageUrl: fanta,
                        price: 1
                    }
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Bears & Raccoons',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: restaurantImage,
        tags: [
            "Italian",
            "2020 Hot",
            "Best Pizza"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Appetizers',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Salad',
                        ingredients: ['Tomato', 'Lettuce', 'Olive Oil'],
                        stockImageUrl: salad,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic Bread',
                        ingredients: ['Garlic', 'Bread', 'Olive Oil'],
                        stockImageUrl: garlicBread,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Wings',
                        ingredients: ['Chicken', 'Soy sauce', 'Chill Sauce'],
                        stockImageUrl: chickenwings,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken Nuggets',
                        ingredients: ['Chicken', 'breadcrumbs'],
                        stockImageUrl: chickennuggets,
                        price: 10
                    },
                    
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Plate',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mashed Potatoes',
                        ingredients: ['Potatoes', 'Sausage', 'Cheese'],
                        stockImageUrl: potatoes,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Nachos',
                        ingredients: ['Tortilla', 'Tomato', 'Onion'],
                        stockImageUrl: nachos,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        ingredients: ['Pepproni', 'Cheese', 'Onion'],
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        ingredients: ['Beef Patty', 'Cheese', 'Onion', 'Tomato', 'Buns'],
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dessert',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Macroon',
                        stockImageUrl: macroon,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tiramisu',
                        stockImageUrl: dessert,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Drinks',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 1
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fanta',
                        stockImageUrl: fanta,
                        price: 1
                    }
                ]
            },
        ]
    }
];

export default data;