import { v4 as uuidv4 } from 'uuid';
import kids from '../assets/kids.png';
import adults from '../assets/adults.png';
import theater from '../assets/theater.png';


const data = [
    {
        id: uuidv4(),
        name: 'MK2 Gambetta',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: theater,
        tags: [
            "BestMovies",
            "LuxurySeats"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Adults',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 1',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 2',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 3',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 4',
                        stockImageUrl: adults,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 5',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 6',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 7',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 8',
                        stockImageUrl: kids,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'MK2 Nation',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: theater,
        tags: [
            "BestMovies",
            "LuxurySeats"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Adults',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 1',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 2',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 3',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 4',
                        stockImageUrl: adults,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 5',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 6',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 7',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 8',
                        stockImageUrl: kids,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Bastille Majestic Cinema Paris',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: theater,
        tags: [
            "BestMovies",
            "LuxurySeats"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Adults',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 1',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 2',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 3',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 4',
                        stockImageUrl: adults,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 5',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 6',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 7',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 8',
                        stockImageUrl: kids,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Max Linder Panorama',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: theater,
        tags: [
            "BestMovies",
            "LuxurySeats"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Adults',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 1',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 2',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 3',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 4',
                        stockImageUrl: adults,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 5',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 6',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 7',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 8',
                        stockImageUrl: kids,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'UGC Ciné Cité Bercy',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: theater,
        tags: [
            "BestMovies",
            "LuxurySeats"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Adults',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 1',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 2',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 3',
                        stockImageUrl: adults,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 4',
                        stockImageUrl: adults,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Movie 5',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 6',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 7',
                        stockImageUrl: kids,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Movie 8',
                        stockImageUrl: kids,
                        price: 10
                    },
                ]
            },
        ]
    },
];

export default data;