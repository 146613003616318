import { v4 as uuidv4 } from 'uuid';
import library from '../assets/library.png';
import booksfiction from '../assets/booksfiction.png';
import science from '../assets/science.png';

const data = [
    {
        id: uuidv4(),
        name: 'SLAM Médiathèques',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: library,
        tags: [
            "BestBooks",
            "BestSections"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fiction',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Science',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: science,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Library History F.Teissedre',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: library,
        tags: [
            "BestBooks",
            "BestSections"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fiction',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Science',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: science,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'The Red Wheelbarrow, Bookstore',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: library,
        tags: [
            "BestBooks",
            "BestSections"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fiction',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Science',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: science,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'LIBRARY NOBELE',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: library,
        tags: [
            "BestBooks",
            "BestSections"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fiction',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Science',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: science,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'La librairie des puf',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: library,
        tags: [
            "BestBooks",
            "BestSections"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fiction',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: booksfiction,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Science',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Book 1',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 2',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 3',
                        stockImageUrl: science,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Book 4',
                        stockImageUrl: science,
                        price: 10
                    },
                ]
            },
        ]
    },
];

export default data;