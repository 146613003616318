interface IRoutes {
    [key: string]: string;
}

const routes : IRoutes = {
    HOME: '/',
    SELECT_STORE: '/select-store',
    STORE_DETAIL: '/details/:storeId',
    ORDER_LIST: '/order/:storeId',
    ORDER_CONFIRM: '/confirm-order/:storeId',
    PAYMENT: '/payment/:storeId',
    PAYMENT_CONFIRM: '/confirm-payment/:storeId',
    WALLET: '/wallet',
    CHAT: '/chat',
    STORE: '/store'
}

export function getIdRoute(route: any, id: any) {
    return route.replace(":storeId", id);
}

export default routes;