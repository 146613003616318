import React, { createContext, useReducer } from 'react';
// import logger from 'use-reducer-logger';

import { IInitialState } from '../interfaces/InitialStateInterface';
import { initialState, storeReducer, StoreAction } from '../reducers/StoreReducer';

const AppContext = createContext<{
  state: IInitialState;
  dispatch: React.Dispatch<StoreAction>;
}>({
  state: initialState,
  dispatch: () => null
});

const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(storeReducer, initialState);
  
    //const [state, dispatch] = useReducer(logger(storeReducer) , initialState);
    
    return (
      <AppContext.Provider value={{state, dispatch}}>
        {children}
      </AppContext.Provider>
    )
}

export { AppContext, AppProvider };