import { v4 as uuidv4 } from 'uuid';
import pharmacy from '../assets/pharmacy.png';
import paracetamol from '../assets/paracetamol.png';
import ibuprofen from '../assets/ibuprofen.png';
import multivitamin from '../assets/multivitamin.png';
import sunscreen from '../assets/sunscreen.png';
import moisturizer from '../assets/moisturizer.png';
import handcream from '../assets/handcream.png';

const data = [
    {
        id: uuidv4(),
        name: 'Pharmacie Pharmavance Ménilmontant',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pharmacy,
        tags: [
            "BestMedicines",
            "SkinCare",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Medicines',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Paracetamol',
                        stockImageUrl: paracetamol,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'IBuprofen',
                        stockImageUrl: ibuprofen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Multi Vitamin',
                        stockImageUrl: multivitamin,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Skin care',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Nivea Sunscreen',
                        stockImageUrl: sunscreen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mosturizer',
                        stockImageUrl: moisturizer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Hand Cream',
                        stockImageUrl: handcream,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Pharmacie du 20e',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pharmacy,
        tags: [
            "BestMedicines",
            "SkinCare",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Medicines',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Paracetamol',
                        stockImageUrl: paracetamol,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'IBuprofen',
                        stockImageUrl: ibuprofen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Multi Vitamin',
                        stockImageUrl: multivitamin,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Skin care',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Nivea Sunscreen',
                        stockImageUrl: sunscreen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mosturizer',
                        stockImageUrl: moisturizer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Hand Cream',
                        stockImageUrl: handcream,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Pharmacie Israel Meyer',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pharmacy,
        tags: [
            "BestMedicines",
            "SkinCare",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Medicines',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Paracetamol',
                        stockImageUrl: paracetamol,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'IBuprofen',
                        stockImageUrl: ibuprofen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Multi Vitamin',
                        stockImageUrl: multivitamin,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Skin care',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Nivea Sunscreen',
                        stockImageUrl: sunscreen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mosturizer',
                        stockImageUrl: moisturizer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Hand Cream',
                        stockImageUrl: handcream,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Pharmacie de la Place de la République',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pharmacy,
        tags: [
            "BestMedicines",
            "SkinCare",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Medicines',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Paracetamol',
                        stockImageUrl: paracetamol,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'IBuprofen',
                        stockImageUrl: ibuprofen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Multi Vitamin',
                        stockImageUrl: multivitamin,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Skin care',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Nivea Sunscreen',
                        stockImageUrl: sunscreen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mosturizer',
                        stockImageUrl: moisturizer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Hand Cream',
                        stockImageUrl: handcream,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Pharmacie Amar',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pharmacy,
        tags: [
            "BestMedicines",
            "SkinCare",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Medicines',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Paracetamol',
                        stockImageUrl: paracetamol,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'IBuprofen',
                        stockImageUrl: ibuprofen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Multi Vitamin',
                        stockImageUrl: multivitamin,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Skin care',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Nivea Sunscreen',
                        stockImageUrl: sunscreen,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mosturizer',
                        stockImageUrl: moisturizer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Hand Cream',
                        stockImageUrl: handcream,
                        price: 10
                    },
                ]
            }
        ]
    }
];

export default data;