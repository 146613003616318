import { v4 as uuidv4 } from 'uuid';
import clothingstore from '../assets/clothingstore.png'
import womanjean from '../assets/womanjean.png';
import womandress from '../assets/womandress.png';
import womanshirt from '../assets/womanshirt.png';
import jumpsuit from '../assets/jumpsuit.png';
import menjean from '../assets/menjean.png';
import menshirt from '../assets/menshirt.png';
import menshorts from '../assets/menshorts.png';
import menpants from '../assets/menpants.png';
import kidpants from '../assets/kidpants.png';
import kidshirt from '../assets/kidshirt.png';
import kidshorts from '../assets/kidshorts.png';

const data = [
    {
        id: uuidv4(),
        name: 'Clothes Attitude',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: clothingstore,
        tags: [
            "BestJeans",
            "Hot Deals",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Women',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: womanjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: womanshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dress',
                        stockImageUrl: womandress,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Jumpsuit',
                        stockImageUrl: jumpsuit,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Men',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: menjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirt',
                        stockImageUrl: menshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: menshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: menpants,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: kidpants,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: kidshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: kidshirt,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Chez PouPoule',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: clothingstore,
        tags: [
            "BestJeans",
            "Hot Deals",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Women',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: womanjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: womanshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dress',
                        stockImageUrl: womandress,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Jumpsuit',
                        stockImageUrl: jumpsuit,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Men',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: menjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirt',
                        stockImageUrl: menshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: menshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: menpants,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: kidpants,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: kidshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: kidshirt,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Du Pareil Au Même',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: clothingstore,
        tags: [
            "BestJeans",
            "Hot Deals",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Women',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: womanjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: womanshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dress',
                        stockImageUrl: womandress,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Jumpsuit',
                        stockImageUrl: jumpsuit,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Men',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: menjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirt',
                        stockImageUrl: menshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: menshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: menpants,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: kidpants,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: kidshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: kidshirt,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Le Comptoir Irlandais Paris 11ème',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: clothingstore,
        tags: [
            "BestJeans",
            "Hot Deals",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Women',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: womanjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: womanshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dress',
                        stockImageUrl: womandress,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Jumpsuit',
                        stockImageUrl: jumpsuit,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Men',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: menjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirt',
                        stockImageUrl: menshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: menshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: menpants,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: kidpants,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: kidshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: kidshirt,
                        price: 10
                    },
                ]
            }
        ]
    },
    {
        id: uuidv4(),
        name: 'Cherry Paris',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: clothingstore,
        tags: [
            "BestJeans",
            "Hot Deals",
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Women',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: womanjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: womanshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dress',
                        stockImageUrl: womandress,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Jumpsuit',
                        stockImageUrl: jumpsuit,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Men',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Jeans',
                        stockImageUrl: menjean,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirt',
                        stockImageUrl: menshirt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: menshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: menpants,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Kids',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pants',
                        stockImageUrl: kidpants,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shorts',
                        stockImageUrl: kidshorts,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Shirts',
                        stockImageUrl: kidshirt,
                        price: 10
                    },
                ]
            }
        ]
    },
];

export default data;