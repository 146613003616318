import { v4 as uuidv4 } from 'uuid';
import hotel from '../assets/hotel.png';
import foodimage from '../assets/foodimage.png';
import burger from '../assets/burger.png';
import cola from '../assets/cola.png';
import room from '../assets/room.png';
import beer from '../assets/beer.png';
import cocktails from '../assets/cocktails.png';
import tapas from '../assets/tapas.png';
import tomatosoup from '../assets/tomatosoup.png';

const data = [
    {
        id: uuidv4(),
        name: 'ibis Paris Père-Lachaise',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: hotel,
        tags: [
            "BestSuite",
            "BestBar",
            "BestRestaurant"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Rooms',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Room 1',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 2',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 3',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 4',
                        stockImageUrl: room,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Restaurant',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tapas',
                        stockImageUrl: tapas,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tomato Soup',
                        stockImageUrl: tomatosoup,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Bar',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beer',
                        stockImageUrl: beer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cocktail',
                        stockImageUrl: cocktails,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'cola',
                        stockImageUrl: cola,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Acacia Hotel',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: hotel,
        tags: [
            "BestSuite",
            "BestBar",
            "BestRestaurant"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Rooms',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Room 1',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 2',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 3',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 4',
                        stockImageUrl: room,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Restaurant',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tapas',
                        stockImageUrl: tapas,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tomato Soup',
                        stockImageUrl: tomatosoup,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Bar',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beer',
                        stockImageUrl: beer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cocktail',
                        stockImageUrl: cocktails,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Anya Hotel',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: hotel,
        tags: [
            "BestSuite",
            "BestBar",
            "BestRestaurant"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Rooms',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Room 1',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 2',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 3',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 4',
                        stockImageUrl: room,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Restaurant',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tapas',
                        stockImageUrl: tapas,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tomato Soup',
                        stockImageUrl: tomatosoup,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Bar',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beer',
                        stockImageUrl: beer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cocktail',
                        stockImageUrl: cocktails,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Aparthotel Adagio access Paris, Philippe Auguste',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: hotel,
        tags: [
            "BestSuite",
            "BestBar",
            "BestRestaurant"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Rooms',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Room 1',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 2',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 3',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 4',
                        stockImageUrl: room,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Restaurant',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tapas',
                        stockImageUrl: tapas,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tomato Soup',
                        stockImageUrl: tomatosoup,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Bar',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beer',
                        stockImageUrl: beer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cocktail',
                        stockImageUrl: cocktails,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Acacia Hotel',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: hotel,
        tags: [
            "BestSuite",
            "BestBar",
            "BestRestaurant"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Rooms',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Room 1',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 2',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 3',
                        stockImageUrl: room,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Room 4',
                        stockImageUrl: room,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Restaurant',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Pizza',
                        stockImageUrl: foodimage,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tapas',
                        stockImageUrl: tapas,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Tomato Soup',
                        stockImageUrl: tomatosoup,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Burger',
                        stockImageUrl: burger,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Bar',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beer',
                        stockImageUrl: beer,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cocktail',
                        stockImageUrl: cocktails,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cola',
                        stockImageUrl: cola,
                        price: 10
                    },
                ]
            },
        ]
    },
];


export default data;