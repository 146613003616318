import { v4 as uuidv4 } from 'uuid';
import pastry from '../assets/pastry.png';
import redvelvetcookie from '../assets/redvelvetcookie.png';
import fudgecookie from '../assets/fudgecookie.png';
import mmcookie from '../assets/mmcookie.png';
import oreocake from '../assets/oreocake.png';
import carrotcake from '../assets/carrot.png';
import chocolatemuffin from '../assets/chocolatemuffin.png';
import redvelvet from '../assets/redvelvet.png';
import chocolatecake from '../assets/chocolatecake.png';
import cookie from '../assets/cookie.png';
import dryfruitmuffin from '../assets/dryfruitmuffin.png';

const data = [
    {
        id: uuidv4(),
        name: 'Ble Sucre',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pastry,
        tags: [
            "BestCookie",
            "BestCake",
            "BestMuffin"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Cookie',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet',
                        stockImageUrl: redvelvetcookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Choclate Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fudge Cookie',
                        stockImageUrl: fudgecookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'M&M Cookie',
                        stockImageUrl: mmcookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Cake',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate cake',
                        stockImageUrl: chocolatecake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Oreo cake',
                        stockImageUrl: oreocake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot cake',
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Muffin',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate Muffin',
                        stockImageUrl: chocolatemuffin,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dry Fruits Muffin',
                        stockImageUrl: dryfruitmuffin,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Chapel',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pastry,
        tags: [
            "BestCookie",
            "BestCake",
            "BestMuffin"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Cookie',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Red Velvet Cookie',
                        stockImageUrl: redvelvetcookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Choclate Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fudge Cookie',
                        stockImageUrl: fudgecookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'M&M Cookie',
                        stockImageUrl: mmcookie,
                        price: 10
                    },
                    
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Cake',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate cake',
                        stockImageUrl: chocolatecake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Oreo cake',
                        stockImageUrl: oreocake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot cake',
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Muffin',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate Muffin',
                        stockImageUrl: chocolatemuffin,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dry Fruits Muffin',
                        stockImageUrl: dryfruitmuffin,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Aux Délices de Franck et Mavilde',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pastry,
        tags: [
            "BestCookie",
            "BestCake",
            "BestMuffin"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Cookie',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Red Velvet Cookie',
                        stockImageUrl: redvelvetcookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Choclate Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fudge Cookie',
                        stockImageUrl: fudgecookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'M&M Cookie',
                        stockImageUrl: mmcookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Cake',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate cake',
                        stockImageUrl: chocolatecake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Oreo cake',
                        stockImageUrl: oreocake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot cake',
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Muffin',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate Muffin',
                        stockImageUrl: chocolatemuffin,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dry Fruits Muffin',
                        stockImageUrl: dryfruitmuffin,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Broken Biscuits',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pastry,
        tags: [
            "BestCookie",
            "BestCake",
            "BestMuffin"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Cookie',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Red Velvet Cookie',
                        stockImageUrl: redvelvetcookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Choclate Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fudge Cookie',
                        stockImageUrl: fudgecookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'M&M Cookie',
                        stockImageUrl: mmcookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Cake',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate cake',
                        stockImageUrl: chocolatecake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Oreo cake',
                        stockImageUrl: oreocake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot cake',
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Muffin',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate Muffin',
                        stockImageUrl: chocolatemuffin,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dry Fruits Muffin',
                        stockImageUrl: dryfruitmuffin,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Haute Pâtisserie',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: pastry,
        tags: [
            "BestCookie",
            "BestCake",
            "BestMuffin"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Cookie',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Red Velvet Cookie',
                        stockImageUrl: redvelvetcookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Choclate Cookie',
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Fudge Cookie',
                        stockImageUrl: fudgecookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'M&M Cookie',
                        stockImageUrl: mmcookie,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Cake',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate cake',
                        stockImageUrl: chocolatecake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Oreo cake',
                        stockImageUrl: oreocake,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot cake',
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Muffin',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Chocolate Muffin',
                        stockImageUrl: chocolatemuffin,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dry Fruits Muffin',
                        stockImageUrl: dryfruitmuffin,
                        price: 10
                    },
                ]
            },
        ]
    },
];

export default data;