import { v4 as uuidv4 } from 'uuid';
import cafe from '../assets/cafe.png';
import capucino from '../assets/capucino.png';
import cafelate from '../assets/cafelate.png';
import expresso from '../assets/expresso.png';
import minttea from '../assets/minttea.png';
import redvelvet from '../assets/redvelvet.png';
import carrotcake from '../assets/carrotcake.png';
import applepie from '../assets/applepie.png';
import cookie from '../assets/cookie.png';
import hotchocolate from '../assets/hotchocolate.png';
import darkchocolate from '../assets/darkchocolate.png';

const data = [
    {
        id: uuidv4(),
        name: 'Le Pure Café',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: cafe,
        tags: [
            "HotChocolate",
            "Bestcoffee",
            "Bestdarkchocolate"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Coffee',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cappucino',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: capucino,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cafe latte',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: cafelate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Expresso',
                        stockImageUrl: expresso,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mint Tea',
                        stockImageUrl: minttea,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Desserts',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        ingredients: ['eggs', 'butter', 'cream'],
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Apple pie',
                        ingredients: ['eggs', 'butter', 'tart'],
                        stockImageUrl: applepie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        ingredients: ['eggs', 'butter', 'flour', 'milk'],
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot Cake',
                        ingredients: ['eggs', 'butter', 'cream', 'carrot'],
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Choclates',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Hot chocolate',
                        stockImageUrl: hotchocolate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dark chocalate',
                        stockImageUrl: darkchocolate,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'À Mon Café',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: cafe,
        tags: [
            "HotChocolate",
            "Bestcoffee",
            "Bestdarkchocolate"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Coffee',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cappucino',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: capucino,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cafe latte',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: cafelate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Expresso',
                        stockImageUrl: expresso,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mint Tea',
                        stockImageUrl: minttea,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Desserts',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        ingredients: ['eggs', 'butter', 'cream'],
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Apple pie',
                        ingredients: ['eggs', 'butter', 'tart'],
                        stockImageUrl: applepie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        ingredients: ['eggs', 'butter', 'flour', 'milk'],
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot Cake',
                        ingredients: ['eggs', 'butter', 'cream', 'carrot'],
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Choclates',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Hot chocolate',
                        stockImageUrl: hotchocolate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dark chocalate',
                        stockImageUrl: darkchocolate,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Café du coin',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: cafe,
        tags: [
            "HotChocolate",
            "Bestcoffee",
            "Bestdarkchocolate"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Coffee',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cappucino',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: capucino,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cafe latte',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: cafelate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Expresso',
                        stockImageUrl: expresso,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mint Tea',
                        stockImageUrl: minttea,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Desserts',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        ingredients: ['eggs', 'butter', 'cream'],
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Apple pie',
                        ingredients: ['eggs', 'butter', 'tart'],
                        stockImageUrl: applepie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        ingredients: ['eggs', 'butter', 'flour', 'milk'],
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot Cake',
                        ingredients: ['eggs', 'butter', 'cream', 'carrot'],
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Choclates',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Hot chocolate',
                        stockImageUrl: hotchocolate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dark chocalate',
                        stockImageUrl: darkchocolate,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Café des Anges',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: cafe,
        tags: [
            "HotChocolate",
            "Bestcoffee",
            "Bestdarkchocolate"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Coffee',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cappucino',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: capucino,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cafe latte',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: cafelate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Expresso',
                        stockImageUrl: expresso,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mint Tea',
                        stockImageUrl: minttea,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Desserts',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        ingredients: ['eggs', 'butter', 'cream'],
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Apple pie',
                        ingredients: ['eggs', 'butter', 'tart'],
                        stockImageUrl: applepie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        ingredients: ['eggs', 'butter', 'flour', 'milk'],
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot Cake',
                        ingredients: ['eggs', 'butter', 'cream', 'carrot'],
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Choclates',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Hot chocolate',
                        stockImageUrl: hotchocolate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dark chocalate',
                        stockImageUrl: darkchocolate,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Raftel Coffee Shop',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: cafe,
        tags: [
            "HotChocolate",
            "BestCoffee",
            "Bestdarkchocolate"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Coffee',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cappucino',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: capucino,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cafe latte',
                        ingredients: ['milk', 'coffee', 'cream'],
                        stockImageUrl: cafelate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Expresso',
                        stockImageUrl: expresso,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Mint Tea',
                        stockImageUrl: minttea,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Desserts',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'RedVelvet Cake',
                        ingredients: ['eggs', 'butter', 'cream'],
                        stockImageUrl: redvelvet,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Apple pie',
                        ingredients: ['eggs', 'butter', 'tart'],
                        stockImageUrl: applepie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Cookie',
                        ingredients: ['eggs', 'butter', 'flour', 'milk'],
                        stockImageUrl: cookie,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot Cake',
                        ingredients: ['eggs', 'butter', 'cream', 'carrot'],
                        stockImageUrl: carrotcake,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Choclates',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Hot chocolate',
                        stockImageUrl: hotchocolate,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Dark chocalate',
                        stockImageUrl: darkchocolate,
                        price: 10
                    },
                ]
            },
        ]
    },
];

export default data;