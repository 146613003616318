import { v4 as uuidv4 } from 'uuid';
import mango from '../assets/mango.png';
import banana from '../assets/banana.png';
import strawberry from '../assets/strawberry.png';
import watermelon from '../assets/watermelon.png';
import cauliflower from '../assets/cauliflower.png';
import carrot from '../assets/carrot.png';
import garlic from '../assets/garlic.png';
import corn from '../assets/corn.png';
import beefPatty from '../assets/beefPatty.png';
import pork from '../assets/pork.png';
import chicken from '../assets/chicken.png';
import milk from '../assets/milk.png';
import yougurt from '../assets/yougurt.png';
import butter from '../assets/butter.png';
import supermarket from '../assets/supermarket.png'

const data = [
    {
        id: uuidv4(),
        name: 'Franprix',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: supermarket,
        tags: [
            "BestVegies",
            "BestFruits",
            "BestDairy",
            "BestMeat"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fruits',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mango',
                        stockImageUrl: mango,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Banana',
                        stockImageUrl: banana,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Strawberry',
                        stockImageUrl: strawberry,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Watermelon',
                        stockImageUrl: watermelon,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Vegetables',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cauliflower',
                        stockImageUrl: cauliflower,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot',
                        stockImageUrl: carrot,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic',
                        stockImageUrl: garlic,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Corn',
                        stockImageUrl: corn,
                        price: 3
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dairy',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Milk',
                        stockImageUrl: milk,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Yogurt',
                        stockImageUrl: yougurt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Butter',
                        stockImageUrl: butter,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Meat',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beef Patty',
                        stockImageUrl: beefPatty,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pork',
                        stockImageUrl: pork,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken',
                        stockImageUrl: chicken,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Carrefour',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: supermarket,
        tags: [
            "BestVegies",
            "BestFruits",
            "BestDairy",
            "BestMeat"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fruits',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mango',
                        stockImageUrl: mango,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Banana',
                        stockImageUrl: banana,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Strawberry',
                        stockImageUrl: strawberry,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Watermelon',
                        stockImageUrl: watermelon,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Vegetables',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cauliflower',
                        stockImageUrl: cauliflower,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot',
                        stockImageUrl: carrot,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic',
                        stockImageUrl: garlic,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Corn',
                        stockImageUrl: corn,
                        price: 3
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dairy',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Milk',
                        stockImageUrl: milk,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Yogurt',
                        stockImageUrl: yougurt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Butter',
                        stockImageUrl: butter,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Meat',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beef Patty',
                        stockImageUrl: beefPatty,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pork',
                        stockImageUrl: pork,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken',
                        stockImageUrl: chicken,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Super U',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: supermarket,
        tags: [
            "BestVegies",
            "BestFruits",
            "BestDairy",
            "BestMeat"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fruits',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mango',
                        stockImageUrl: mango,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Banana',
                        stockImageUrl: banana,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Strawberry',
                        stockImageUrl: strawberry,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Watermelon',
                        stockImageUrl: watermelon,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Vegetables',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cauliflower',
                        stockImageUrl: cauliflower,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot',
                        stockImageUrl: carrot,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic',
                        stockImageUrl: garlic,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Corn',
                        stockImageUrl: corn,
                        price: 3
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dairy',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Milk',
                        stockImageUrl: milk,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Yogurt',
                        stockImageUrl: yougurt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Butter',
                        stockImageUrl: butter,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Meat',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beef Patty',
                        stockImageUrl: beefPatty,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pork',
                        stockImageUrl: pork,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken',
                        stockImageUrl: chicken,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Supermarche G20',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: supermarket,
        tags: [
            "BestVegies",
            "BestFruits",
            "BestDairy",
            "BestMeat"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fruits',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mango',
                        stockImageUrl: mango,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Banana',
                        stockImageUrl: banana,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Strawberry',
                        stockImageUrl: strawberry,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Watermelon',
                        stockImageUrl: watermelon,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Vegetables',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cauliflower',
                        stockImageUrl: cauliflower,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot',
                        stockImageUrl: carrot,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic',
                        stockImageUrl: garlic,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Corn',
                        stockImageUrl: corn,
                        price: 3
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dairy',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Milk',
                        stockImageUrl: milk,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Yogurt',
                        stockImageUrl: yougurt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Butter',
                        stockImageUrl: butter,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Meat',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beef Patty',
                        stockImageUrl: beefPatty,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pork',
                        stockImageUrl: pork,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken',
                        stockImageUrl: chicken,
                        price: 10
                    },
                ]
            },
        ]
    },
    {
        id: uuidv4(),
        name: 'Mini Market',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        imageUrl: supermarket,
        tags: [
            "BestVegies",
            "BestFruits",
            "BestDairy",
            "BestMeat"
        ],
        miles: "496m",
        categories: [
            {
                id: uuidv4(),
                categoryName: 'Fruits',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Mango',
                        stockImageUrl: mango,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Banana',
                        stockImageUrl: banana,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Strawberry',
                        stockImageUrl: strawberry,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Watermelon',
                        stockImageUrl: watermelon,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Vegetables',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Cauliflower',
                        stockImageUrl: cauliflower,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Carrot',
                        stockImageUrl: carrot,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Garlic',
                        stockImageUrl: garlic,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Corn',
                        stockImageUrl: corn,
                        price: 3
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Dairy',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Milk',
                        stockImageUrl: milk,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Yogurt',
                        stockImageUrl: yougurt,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Butter',
                        stockImageUrl: butter,
                        price: 10
                    },
                ]
            },
            {
                id: uuidv4(),
                categoryName: 'Meat',
                stocks: [
                    {
                        id: uuidv4(),
                        stockName: 'Beef Patty',
                        stockImageUrl: beefPatty,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Pork',
                        stockImageUrl: pork,
                        price: 10
                    },
                    {
                        id: uuidv4(),
                        stockName: 'Chicken',
                        stockImageUrl: chicken,
                        price: 10
                    },
                ]
            },
        ]
    },
];

export default data;