import { IInitialState } from '../interfaces/InitialStateInterface';
import data from '../util/data';

export const initialState: IInitialState = {
    stores: data['Restaurant'],
    selectedCategory: 'Restaurant',
    details: {
        id: '',
        name: '',
        description: '',
        imageUrl: '',
        tags: [],
        miles: '',
        categories: []
    },
    setHeader: false,
    setHeaderHeading: {
        content: '',
        display: false
    },
    pos: 0,
    sliding: false,
    direction: 'NEXT',
    counter: [],
    cartDetails: {
        restaurantId:'' ,
        restaurantName: '',
        cartList: []
    },
    orderType: {
        option: '',
        value: ''
    },
    paymentHeader: false,
    setCurrentPage: '',
    walletHeader: false,
}


export type StoreAction =
    | { type: 'LOAD_STORE_TYPE', payload: string }
    | { type: 'STORE_DETAILS', payload: string }
    | { type: 'SET_HEADER', payload: boolean }
    | { type: 'SET_HEADER_HEADING', payload: {
        content: string,
        display: boolean
    }}
    | { type: 'PREV' | 'NEXT', payload: number }
    | { type: 'STOP_SLIDING' | 'RESET' }
    | { type: 'COUNTER', payload: {
        id:string
    }}
    | { type: 'INCREMENT_COUNTER' | 'DECREMENT_COUNTER', payload: {
        id: string
    }}
    | {type: "CLEAR_COUNTER"}
    | { type: 'ADD_TO_CART', payload: {
        restaurantId: string,
        restaurantName: string,
        stockId: string,
        stockName: string,
        stockPrice: number,
        stockImageUrl: string,
        ingredients: string[]
    }} 
    | {
        type: 'ADD_TYPE_OF_ORDER', payload: {
            'option' : string,
            'value' : string
    }}
    | { type: 'SET_PAYMENT_CONFIRM_HEADER', payload: boolean }
    | { type: 'SET_CURRENT_PAGE', payload: string}
    | { type: 'SET_WALLET_HEADER', payload: boolean }


export const storeReducer = (state: IInitialState, action: StoreAction): IInitialState => {
    switch (action.type) {
        case 'LOAD_STORE_TYPE': 
            return{
                ...state,
                stores: data[action.payload],
                selectedCategory: action.payload
            }
        case 'STORE_DETAILS':
            const foundData = state.stores.find(el => el.id === action.payload);
            return{
                ...state,
                details: foundData,
            }
        case 'SET_HEADER':
            return {
                ...state,
                setHeader: action.payload
            }
        case 'RESET':
            return initialState;
        case 'PREV':
            return {
                ...state,
                  direction: 'PREV',
                  sliding: true,
                  pos: state.pos === 0 ? action.payload - 1 : state.pos - 1
            };
        case 'NEXT':
            return {
                ...state,
                direction: 'NEXT',
                sliding: true,
                pos: state.pos === action.payload - 1 ? 0 : state.pos + 1
            };
        case 'STOP_SLIDING':
            return { 
                ...state,
                sliding: false
            };
        case 'COUNTER':
            return{
                ...state,
                counter: [...state.counter, {
                    id: action.payload.id,
                    count: 0
                }]
            }
        case 'INCREMENT_COUNTER': 
            const incrementCountIndex = state.counter.findIndex(el => el.id === action.payload.id);
            if(incrementCountIndex !== -1){
                state.counter[incrementCountIndex] = {
                    id: action.payload.id,
                    count: state.counter[incrementCountIndex].count + 1
                }
            }
            return {
                ...state,
                counter: state.counter
            }
        case 'DECREMENT_COUNTER':
            const decrementCountIndex = state.counter.findIndex(el => el.id === action.payload.id);
            if(decrementCountIndex !== -1){
                state.counter[decrementCountIndex] = {
                    id: action.payload.id,
                    count: state.counter[decrementCountIndex].count - 1
                }
            }
            return {
                ...state,
                counter: state.counter

            }
        case 'CLEAR_COUNTER':
            return {
                ...state,
                details:{
                    id: '',
                    name: '',
                    description: '',
                    imageUrl: '',
                    tags: [],
                    miles: '',
                    categories: []
                },
                counter: [],
                cartDetails: {
                    restaurantId:'' ,
                    restaurantName: '',
                    cartList: []
                }
            }
        case 'ADD_TO_CART':
            const index = state.cartDetails.cartList.findIndex(el => el.stockId === action.payload.stockId);
            const counterIndex = state.counter.findIndex(el => el.id === action.payload.stockId);
            if(index !== -1){
                state.cartDetails.cartList[index] = {
                    stockId: action.payload.stockId,
                    stockName: action.payload.stockName,
                    stockPrice: action.payload.stockPrice,
                    stockQuantity: state.counter[counterIndex].count,
                    stockImageUrl: action.payload.stockImageUrl,
                    ingredients: action.payload.ingredients
                }
            }else{
                state.cartDetails.cartList = [...state.cartDetails.cartList, {
                    stockId: action.payload.stockId,
                    stockName: action.payload.stockName,
                    stockPrice: action.payload.stockPrice,
                    stockQuantity: state.counter[counterIndex].count,
                    stockImageUrl: action.payload.stockImageUrl,
                    ingredients: action.payload.ingredients
                }]
            }
            return {
                ...state,
                cartDetails: {
                    restaurantId : action.payload.restaurantId,
                    restaurantName : action.payload.restaurantName,
                    cartList: state.cartDetails.cartList.filter(el=> el.stockQuantity !== 0)
                }
            }
        case 'SET_HEADER_HEADING': 
            return {
                ...state,
                setHeaderHeading:{
                    content: action.payload.content,
                    display: action.payload.display
                }
            }
        case 'ADD_TYPE_OF_ORDER': 
            return{
                ...state,
                orderType: action.payload
            }
        case 'SET_PAYMENT_CONFIRM_HEADER': 
            return {
                ...state,
                paymentHeader: action.payload
            }
        case 'SET_CURRENT_PAGE': 
            return {
                ...state,
                setCurrentPage: action.payload
            }
        case 'SET_WALLET_HEADER':
            return {
                ...state,
                walletHeader: action.payload
            }
        default:
            return state;
    }
}
